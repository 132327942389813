import {
  Stack,
  Typography,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//meetingStore
import { meetingStore } from "@state/store";

export type VTVideoSelector = {
  selectedDevice: string;
  setSelectedDevice: (selectedDevice: string) => void;
  handleVideo: () => void;
  isMobile?: boolean;
};

const VideoSelector = ({
  handleVideo,
  selectedDevice,
  setSelectedDevice,
  isMobile,
}: VTVideoSelector) => {
  const {
    videoDevices,
    setVideoDevices,
    setSelectedVideoStream,
    selectedVideoStream,
    activeVideoStream,
    videoIsOn,
    setVideoIsOn,
  } = meetingStore();

  const handleChange = (e: SelectChangeEvent) => {
    setSelectedDevice(e.target.value);
    setSelectedVideoStream(e.target.value);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: isMobile ? "space-between" : "flex-start",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <VideocamOutlinedIcon sx={{ color: "white" }} />
          <Typography
            sx={{
              color: "white",
              paddingLeft: "10px",
              whiteSpace: "no-wrap",
              fontSize: "1rem",
            }}
          >
            Video is on
          </Typography>
        </Box>
        <Switch
          sx={{
            marginLeft: "20px",
          }}
          checked={videoIsOn}
          onClick={handleVideo}
        />
      </Box>

      {videoDevices.length > 0 ? (
        <Select
          disabled={!videoIsOn}
          onChange={(e) => {
            handleChange(e);
          }}
          value={selectedDevice}
          label="Video Source"
          sx={{
            width: "100%",
            background: "transparent",
            color: "white",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  padding: 1,
                },
                marginLeft: "8px",
              },
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon
              style={{
                color: videoIsOn ? "white" : "rgba(0, 0, 0, 0.23)",
                cursor: "pointer",
                fontSize: "30px",
              }}
              {...props}
            />
          )}
        >
          {videoDevices.map(
            (videoSource: {
              deviceId: string;
              groupId: string;
              kind: string;
              label: string;
            }) => (
              <MenuItem key={videoSource.deviceId} value={videoSource.deviceId}>
                {videoSource.label}
              </MenuItem>
            )
          )}
        </Select>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default VideoSelector;
