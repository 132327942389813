export const createVideoElement = (stream, _id) =>
  new Promise((resolve, reject) => {
    const existingVideoElement = document.getElementById(_id);
    if (existingVideoElement) {
      reject(new Error(`${_id} already exists`));
    } else {
      const videoElement = document.createElement("video");
      videoElement.id = _id;
      videoElement.style = "display:none;";
      videoElement.width = 480;
      videoElement.height = 320;
      videoElement.muted = true;
      videoElement.setAttribute("playsinline", true);
      document.body.appendChild(videoElement);
      videoElement.srcObject = stream;
      videoElement.play();
      videoElement.onloadeddata = (e) => {
        const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
        videoElement.width = videoElement.height * aspectRatio;
        videoElement.height = videoElement.width / aspectRatio;
        resolve(e.target);
      };
    }
  });

export const createCanvasElement = (dimensions, _id) => {
  const canvas = document.createElement("canvas");
  canvas.id = _id;
  canvas.style = "display:none;";
  canvas.width = dimensions.videoWidth || 480;
  canvas.height = dimensions.videoHeight || 320;
  document.body.appendChild(canvas);
  return canvas;
};

// Fits image/frame on canvas to maintain aspect ratio
export const fitImageOn = (canvas, imageObj) => {
  const context = canvas.getContext("2d");
  const imageAspectRatio = imageObj.videoWidth / imageObj.videoHeight;
  const canvasAspectRatio = canvas.width / canvas.height;
  let renderableHeight;
  let renderableWidth;
  let xStart;
  let yStart;

  // If image's aspect ratio is less than canvas's we fit on height
  // and place the image centrally along width
  if (imageAspectRatio < canvasAspectRatio) {
    renderableHeight = canvas.height;
    renderableWidth =
      imageObj.videoWidth * (renderableHeight / imageObj.videoHeight);
    xStart = (canvas.width - renderableWidth) / 2;
    yStart = 0;
  }

  // If image's aspect ratio is greater than canvas's we fit on width
  // and place the image centrally along height
  else if (imageAspectRatio > canvasAspectRatio) {
    renderableWidth = canvas.width;
    renderableHeight =
      imageObj.videoHeight * (renderableWidth / imageObj.videoWidth);
    xStart = 0;
    yStart = (canvas.height - renderableHeight) / 2;
  }

  // Happy path - keep aspect ratio
  else {
    renderableHeight = canvas.height;
    renderableWidth = canvas.width;
    xStart = 0;
    yStart = 0;
  }
  context.drawImage(
    imageObj,
    xStart,
    yStart,
    renderableWidth,
    renderableHeight
  );
};

// CPU Blur effect used for Safari due to lack of
// CanvasRenderingContext2D.filter support
export const cpuBlur = (canvas, blur) => {
  const ctx = canvas.getContext("2d");
  let sum = 0;
  const delta = 5;
  const alpha_left = 1 / (2 * Math.PI * delta * delta);
  const step = blur < 3 ? 1 : 2;
  for (let y = -blur; y <= blur; y += step) {
    for (let x = -blur; x <= blur; x += step) {
      const weight =
        alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta));
      sum += weight;
    }
  }
  // let count = 0;
  for (let y = -blur; y <= blur; y += step) {
    for (let x = -blur; x <= blur; x += step) {
      // count++;
      ctx.globalAlpha =
        ((alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta))) /
          sum) *
        blur;
      ctx.drawImage(canvas, x, y);
    }
  }
  ctx.globalAlpha = 1;
};
