import {
  Stack,
  Typography,
  Switch,
  Select,
  MenuItem,
  Box,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";

import { meetingStore } from "@state/store";
import AudioMeter from "../../../AudioMeter";

import { getAudio, getDeviceList } from "@utilities/deviceConnection";

interface VTMicrophoneSelector {
  isLoaded?: boolean;
  selectedMicrophone: string;
  setSelectedMicrophone: (selectedMicrophone: string) => void;
  isMobile?: boolean;
}

const MicrophoneSelector = ({
  isLoaded,
  selectedMicrophone,
  setSelectedMicrophone,
  isMobile,
}: VTMicrophoneSelector) => {
  const {
    microphones,
    setMicrophones,
    setSelectedMicrophoneStream,
    selectedMicrophoneStream,
    setActiveAudioStream,
    activeAudioStream,
    microphoneIsOn,
    setMicrophoneIsOn,
  } = meetingStore();
  const constraints = {
    audio: {
      deviceId: { exact: selectedMicrophoneStream },
    },
    video: false,
  };

  const [testIsOn, setTestIsOn] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);
  const maxVolume: number = Math.max(0, Math.min(100, progress));

  let audioContext: AudioContext | null;
  let processor: any;

  useEffect(() => {
    if (selectedMicrophoneStream) {
      getAudio(constraints).then((stream) => {
        if (stream.stream) {
          setActiveAudioStream(stream.stream);
          setMicrophoneIsOn(true);
        } else {
          console.log("User disabled permissions in browsers");
          setMicrophoneIsOn(false);
        }
      });
    }
  }, [selectedMicrophoneStream]);

  const handleChange = (e: SelectChangeEvent) => {
    setTestIsOn(false);
    setSelectedMicrophone(e.target.value);
    setSelectedMicrophoneStream(e.target.value);
  };

  const handleMicrophone = () => {
    setMicrophoneIsOn(!microphoneIsOn);
    if (microphoneIsOn === true) {
      if (activeAudioStream) {
        const tracks = activeAudioStream.getAudioTracks();
        tracks[0].enabled = true;
      }
    } else {
      if (activeAudioStream) {
        const tracks = activeAudioStream.getAudioTracks();
        tracks[0].enabled = false;
      }
    }
  };

  const turnOn = () => {
    setTestIsOn(true);
    if (activeAudioStream) {
      audioContext = new AudioContext();
      processor = audioContext.createScriptProcessor(2048, 1, 1);
      const mediaStreamSource =
        //@ts-ignore
        audioContext.createMediaStreamSource(activeAudioStream);
      mediaStreamSource.connect(audioMeterOn(audioContext, processor));
    } else {
      console.log("there is no activeAudioStream");
    }
  };

  const audioMeterOn = (context: AudioContext, processor: any) => {
    processor.volume = 0.0;
    processor.connect(context.destination);

    processor.onaudioprocess = (event: any) => {
      const input = event.inputBuffer.getChannelData(0);

      let sum = 0.0;
      for (let i = 0; i < input.length; ++i) {
        sum += input[i] * input[i];
      }

      processor.volume = Math.sqrt(sum / input.length) * 300;

      setProgress(processor.volume);
    };

    return processor;
  };

  const turnOff = () => {
    processor = null;
    audioContext = null;
    setTestIsOn(false);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: isMobile ? "space-between" : "flex-start",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <KeyboardVoiceOutlinedIcon sx={{ color: "white" }} />
          <Typography
            sx={{
              color: "white",
              marginLeft: "10px",
              whiteSpace: "no-wrap",
              fontSize: "1rem",
            }}
          >
            Mic is on
          </Typography>
        </Box>
        <Switch
          sx={{ marginLeft: "20px" }}
          checked={microphoneIsOn}
          onClick={handleMicrophone}
        />
      </Box>

      {microphones.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Select
                disabled={!microphoneIsOn}
                onChange={(e) => {
                  handleChange(e);
                }}
                sx={{
                  background: "transparent",
                  color: "white",
                  width: isMobile ? "100%" : "250px",
                }}
                IconComponent={(props) => (
                  <ExpandMoreIcon
                    style={{
                      color: microphoneIsOn ? "white" : "rgba(0, 0, 0, 0.23)",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                    {...props}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        padding: 1,
                      },
                    },
                  },
                }}
                value={selectedMicrophone}
                label="Microphone"
              >
                {microphones &&
                  microphones.map(
                    (microphone: {
                      deviceId: string;
                      groupId: string;
                      kind: string;
                      label: string;
                    }) => (
                      <MenuItem
                        key={microphone.deviceId}
                        value={microphone.deviceId}
                      >
                        {microphone.label}
                      </MenuItem>
                    )
                  )}
              </Select>
            </Box>

            {isMobile ? (
              <></>
            ) : (
              <Box sx={{}}>
                {testIsOn ? (
                  <Button
                    onClick={() => {
                      turnOff();
                    }}
                    sx={{
                      color: "white",
                      border: "1px solid white",
                      maxWidth: "50px",
                      whiteSpace: "nowrap",
                      height: "100%",
                      ml: 2,
                      fontSize: "12px",
                    }}
                  >
                    Stop Test
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      turnOn();
                    }}
                    disabled={!microphoneIsOn}
                    sx={{
                      color: "white",
                      border: "1px solid white",
                      height: "100%",
                      maxWidth: "50px",
                      width: "100%",
                      ml: 2,
                    }}
                  >
                    Test
                  </Button>
                )}
              </Box>
            )}
          </Box>

          {isMobile ? (
            <></>
          ) : (
            <AudioMeter
              audioLevel={maxVolume}
              testIsOn={testIsOn}
              microphoneIsOn={microphoneIsOn}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default MicrophoneSelector;
