import { useState, useEffect } from "react";
import { Box } from "@mui/material";

export type VTAudioMeter = {
  audioLevel: number;
  testIsOn: boolean;
  microphoneIsOn: boolean;
};
const AudioMeter = ({ audioLevel, testIsOn, microphoneIsOn }: VTAudioMeter) => {
  const [array, setArray] = useState<any>([]);

  useEffect(() => {
    const numberArray: any[] = [];
    const createNumberArray = () => {
      for (let i = 0; i < 45; i++) {
        numberArray.push(i);
      }
      setArray(numberArray);
    };
    createNumberArray();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "10px",
        justifyContent: " space-between",
      }}
    >
      {array.map((newElement: number) => (
        <Box
          key={newElement}
          sx={{
            height: "12px",
            width: "3.21px",
            background: "rgba(0, 174, 239, 0.35)",
            borderRadius: "2px",
            backgroundColor:
              audioLevel / (100 / array.length) > newElement &&
              testIsOn &&
              microphoneIsOn
                ? "#00AEEF"
                : null,
          }}
          className="bar"
        />
      ))}
    </Box>
  );
};

export default AudioMeter;
