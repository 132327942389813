import { load, drawBokehEffect } from "@tensorflow-models/body-pix";
// Adds all three TFJS backends to the global backend registry.
import "@tensorflow/tfjs-backend-webgl";
import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-wasm";

import {
  createVideoElement,
  createCanvasElement,
  loadDeviceConfigs,
} from "./utils";

const isiOSSafari = () => false;

const options = {
  architecture: "MobileNetV1",
  outputStride: 16,
  multiplier: 0.5, // TODO: Switch from 0.50 to 0.75 for faster machines
  quantBytes: 2,
};
export const createBlurEffect = (
  stream,
  dimensions = {},
  deviceId = "default"
) =>
  new Promise(async (resolve, reject) => {
    const net = await load(options);

    const videoElement = await createVideoElement(stream, `${deviceId}-video`);
    const canvas = createCanvasElement(dimensions, `${deviceId}-canvas`);

    const blurbgLoop = setInterval(async () => {
      const segmentation = await net.segmentPerson(videoElement, {
        flipHorizontal: false,
        ...loadDeviceConfigs(),
      });
      const backgroundBlurAmount = 6;
      const edgeBlurAmount = isiOSSafari ? 3 : 6;
      const flipHorizontal = false;
      drawBokehEffect(
        canvas,
        videoElement,
        segmentation,
        backgroundBlurAmount,
        edgeBlurAmount,
        flipHorizontal
      );
    }, 0);

    window[`${deviceId}-loop`] = blurbgLoop;
    const canvasElement = document.getElementById(`${deviceId}-canvas`);
    canvasElement.getContext("2d");
    resolve(await canvasElement.captureStream());
  });

export const stopBlurEffect = (deviceId = "default") =>
  new Promise((resolve, reject) => {
    document.getElementById(`${deviceId}-canvas`).outerHTML = "";
    document.getElementById(`${deviceId}-video`).outerHTML = "";

    if (window[`${deviceId}-loop`] === undefined)
      reject(new Error("Background blur loop is not defined"));
    window.clearInterval(window[`${deviceId}-loop`]);
    resolve();
  });
