/////////////////////////////////////////////////////////////////////////////////////////////
/////////
/////////             Obtaining initials for circles
/////////
/////////////////////////////////////////////////////////////////////////////////////////////

function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`.substr(-2);
  }
  return color;
}

export function getOwnerInitials(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name || ""),
      height: 25,
      width: 25,
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
    },
    children: name?.includes(" ")
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name?.[0] || ""}`,
  };
}
