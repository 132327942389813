////////////////////////////////////////////////////////////
/////////
/////////   TS File to handle all our export functions
/////////   to obtain audio and video
/////////
////////////////////////////////////////////////////////////

// const dictionary = {
//   audioinput: "audioInput",
//   audiooutput: "audioOutput",
//   videoinput: "videoInput",
// };

/**
 *
 * @param {string} kind - The type of the device. One of "audioInput",
 * "audioOutput", and "videoInput". Also supported is all lowercase versions
 * of the preceding types.
 * @returns {MediaDeviceInfo[]} - MediaDeviceInfo Array of given param
 */

export const getDeviceList = async (kind: string) => {
  return await navigator.mediaDevices.enumerateDevices().then((sources) => {
    return sources.filter((source) => {
      return source.kind === kind;
    });
  });
};

/**
 *
 * @param {Object} constraints - constraints to be passed to getUserMedia API.
 *  {audio:false, video: { deviceId: exact: {selectedVideoStream}}}
 *  and additional parameters such as facingMode as user, height of video, and width of video
 * @returns {stream:MediaStream, error:Error | null}
 *
 *
 **/

export const getVideo = async (constraints: {
  audio: boolean;
  video: {
    deviceId: {
      exact: string;
    };
  };
  facingMode?: string;
  height?: {
    min: number;
    ideal: number;
    max: number;
  };
  width?: {
    min: number;
    ideal: number;
    max: number;
  };
  aspectRatio: number;
}) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return { stream: stream };
  } catch (error) {
    return { stream: null, error: Error };
  }
};

/**
 *
 * @param {Object} constraints - constraints to be passed to getUserMedia API.
 *  {audio:{deviceId:exact:string}, video:boolean
 * @returns {stream:MediaStream, error:Error | null}
 *
 *
 **/

export const getAudio = async (constraints: {
  audio: {
    deviceId: {
      exact: string;
    };
  };
  video: boolean;
}) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return { stream: stream };
  } catch (error) {
    return { stream: null, Error };
  }
};
