import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import Slider from "@mui/material/Slider";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { meetingStore } from "@state/store";

const SpeakerSelector = () => {
  const {
    audioOutput,
    setAudioOutput,
    setSelectedSpeakerStream,
    activeSpeakerStream,
    setActiveSpeakerStream,
    volume,
    setVolume,
  } = meetingStore();

  const [selectedSpeaker, setSelectedSpeaker] = useState<string>("");
  const [testIsOn, setTestIsOn] = useState<boolean>(false);
  const [audioElement, setAudioElement] = useState<HTMLMediaElement | null>(
    null
  );

  // useEffect(() => {
  //   if (navigator.mediaDevices) {
  //     navigator.mediaDevices.enumerateDevices().then(function (sources) {
  //       const audioSourcesFromMediaDevices = sources.filter(function (source) {
  //         return source.kind === "audiooutput";
  //       });
  //       setAudioOutput(audioSourcesFromMediaDevices);
  //       setSelectedSpeaker(audioSourcesFromMediaDevices[0].deviceId);
  //     });
  //   }
  // }, []);

  // const handleChange = (e: SelectChangeEvent) => {
  //   setSelectedSpeaker(e.target.value);
  //   setSelectedSpeakerStream(e.target.value);
  // };

  // const audio = new Audio("https://v2.visionable.com/test/assets/ring.mp3");
  // const testAudio = new Audio(
  //   "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
  // );

  // const audioElement: HTMLAudioElement = new Audio(
  //   "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
  // );

  useEffect(() => {
    //@ts-ignore
    const audio: HTMLMediaElement = document.getElementById("audio");
    setAudioElement(audio);
  }, []);

  const handleStart = () => {
    //@ts-ignore
    audioElement.play();
    setTestIsOn(true);
  };

  const handleStop = () => {
    //@ts-ignore
    audioElement.pause();
    setTestIsOn(false);
  };

  // useEffect(() => {
  //   if (testIsOn) {
  //     setTimeout(() => {
  //       handleStop();
  //     }, 3000);
  //   }
  // }, [testIsOn]);

  const changeMasterValue = (e: Event, newValue: number | number[]) => {
    //@ts-ignore
    audioElement.volume = (newValue as number) / 100;
    setVolume((newValue as number) / 100);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <VolumeUpOutlinedIcon sx={{ color: "white" }} />
        <Typography sx={{ color: "white", marginLeft: "20px" }}>
          Speaker
        </Typography>

        <audio
          id="audio"
          src="https://v2.visionable.com/test/assets/ring.mp3"
        ></audio>

        {testIsOn ? (
          <Button
            onClick={handleStop}
            id="stop-button"
            sx={{
              color: "white",
              border: "1px solid white",
              marginLeft: "10px",
            }}
          >
            Stop Test
          </Button>
        ) : (
          <>
            <Button
              id="play-button"
              onClick={handleStart}
              sx={{
                color: "white",
                border: "1px solid white",
                marginLeft: "10px",
              }}
            >
              Test
            </Button>
          </>
        )}
      </Box>

      <Slider
        value={volume * 100}
        onChange={changeMasterValue}
        id="volume"
        aria-label="Volume"
        size="small"
        sx={{
          "& .MuiSlider-track": { background: "#00AEEF" },
          "& .MuiSlider-thumb": {
            color: "white",
            "&:before": {
              boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
            },
            "&:hover, &.Mui-focusVisible, &.Mui-active": {
              boxShadow: "none",
            },
          },
        }}
      />
    </Box>
  );
};

export default SpeakerSelector;
